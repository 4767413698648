import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {

    public changeChatsSubject: Subject<any>;

    constructor(
    ) {
        this.changeChatsSubject = new Subject();
    }

}