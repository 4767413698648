import { Action } from '@ngrx/store';

export enum AuthActionTypes {
    Login = '[Auth] Login',
    Logout = '[Logout] Action'
}

export class Login implements Action {
    readonly type = AuthActionTypes.Login;
    constructor(public payload: { user: any }) {}
}

export class Logout implements Action {
    readonly type = AuthActionTypes.Logout;
}

export type AuthActions = Login | Logout;
