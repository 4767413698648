import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EncryptionService } from '@app/shared/services/encryption.service';

@Injectable()
export class HttpRequestDecryptInterceptor implements HttpInterceptor {

    constructor(
        private encryptionService: EncryptionService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    const data = event.body;
                    if (data.mswSANXnWqh && Array.isArray(data.mswSANXnWqh)) {
                        const tmp = this.encryptionService.decrypt(data.mswSANXnWqh);
                        const res = JSON.parse(tmp);
                        event = event.clone({ body: res });
                    }
                }      

                return event;
            }),
            catchError(error => {
                const data = error.error;
                if (data.mswSANXnWqh && Array.isArray(data.mswSANXnWqh)) {
                    const tmp = this.encryptionService.decrypt(data.mswSANXnWqh);
                    const res = JSON.parse(tmp);
                    error.error = res;
                }

                return throwError(error);
            })

        );
    }

}