import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { NbTokenService } from '@nebular/auth';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private nbTokenService: NbTokenService
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.nbTokenService.get().pipe(
      switchMap(data => {
          const token = data.getValue();
          if (token) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`
              }
            });
          }

        return next.handle(request);
      })
    );
  }
}