import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as Forge from 'node-forge';

@Injectable({
    providedIn: 'root'
})
export class EncryptionService {

    constructor(
    ) {
    }

    encryptWithPublicKeyLong(valueToEncrypt: string, keyPublica: string): any {
        let keyFinal: string = "-----BEGIN PUBLIC KEY-----  ";
        keyFinal = keyFinal + keyPublica;
        keyFinal = keyFinal + "  -----END PUBLIC KEY-----";

        const rsa = Forge.pki.publicKeyFromPem(keyFinal);
        const val = this.splitPlaintext(rsa, valueToEncrypt.toString());

        return val;
    }

    splitPlaintext(publicKey, plaintext) {
        plaintext = Forge.util.encodeUtf8(plaintext);
        const len = 80;
        if (plaintext.length < len) {
            return [Forge.util.bytesToHex(publicKey.encrypt(plaintext))];
        }

        const result = [];
        const splitCount = Math.ceil(plaintext.length / len);
        for (let i = 0; i < splitCount; i++) {
            const str = plaintext.substring(i * len, (i + 1) * len);
            result.push(Forge.util.bytesToHex(publicKey.encrypt(str)));
        }

        return result;
    }

    decrypt(value: any[]): any {
        let keyFinal: string = "-----BEGIN PRIVATE KEY-----";
        keyFinal = keyFinal + environment.descifrado;
        keyFinal = keyFinal + "-----END PRIVATE KEY-----";

        const rsa = Forge.pki.privateKeyFromPem(keyFinal);
        let plain = '';
        for (const val of value) {
            const tmp = Forge.util.decodeUtf8(rsa.decrypt(Forge.util.hexToBytes(val)));
            plain += tmp;
        }

        return plain;
    }

}