import { Component, OnInit, OnDestroy } from '@angular/core';

import { MENU_ITEMS } from './menu';

import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { LayoutService } from '@app/shared/services/layout.service';
import { Firestore, collection, onSnapshot, where, startAfter, startAt, endAt, query, orderBy, limit, getDocs, doc, getDoc, setDoc } from '@angular/fire/firestore';

import { Store, select } from '@ngrx/store';
import { AppState, currentUser } from '@app/store';

import { findIndex } from 'lodash';

const LIMIT = 1000;

@Component({
    selector: 'layout',
    styleUrls: ['./layout.component.scss'],
    templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit, OnDestroy {

	private _unsubscribeAll: Subject<any> = new Subject<any>();
    menu = MENU_ITEMS;

	unsubscribeSnapshot: any;
	user: any;
    list: any[];

    constructor(
		private firestore: Firestore,
		private store: Store<AppState>,
        private layoutService: LayoutService
    ) {
    }

    ngOnInit(): void {
        this.layoutService.changeChatsSubject.pipe(
			takeUntil(this._unsubscribeAll),
        ).subscribe(data => {
            this.list = data.list;
            const items = data.list.map((ob: any) => {
                const newOb: any = { title: ob.nameInsurer, link: 'chat/' + ob.idChannel };
                if (ob.unreadMsg > 0) {
                    newOb.badge = { text: ob.unreadMsg, status: 'primary' };
                }

                return newOb;
            });
            this.menu[1].children = items;
        });

		this.store.pipe(
			takeUntil(this._unsubscribeAll),
			select(currentUser),
			filter(user => user)
		).subscribe({
			next: (user: any) => {
				this.user = user;
                this.snapshotList();
			}
		});
    }

    snapshotList(): void {
		const ref = collection(this.firestore, `channels/${this.user.userId}/${this.user.userId}-channels`);
		let obQuery = query(ref, orderBy('lastMsg', 'desc'), limit(LIMIT));
		getDocs(obQuery).then((data: any) => {
			const map = data.docs.map((ob: any) => {
				const tmp = ob.data(); 
				return tmp;
			});

			this.list = map;
			this.layoutService.changeChatsSubject.next({ list: this.list });
		});

		let initialData = true;
		this.unsubscribeSnapshot = onSnapshot(obQuery, (snapshot: any) => {
			if (snapshot.docs.length > 0 && !initialData) {
				const map = snapshot.docs.map((ob: any) => {
					const tmp = ob.data(); 
					return tmp;
				});

				map.forEach((item, index) => {
					const ob = this.list[index];
					if (item.channel === ob.channel) {
						if (item.unreadMsg !== ob.unreadMsg) {
                            if (item.unreadMsg > 0) {
								this.list.splice(index, 1);
								this.list.unshift(item);
                            } else {
                                this.list[index].unreadMsg = item.unreadMsg;
                            }
						}
					} else {
						const ind = findIndex(this.list, { channel: item.channel });
						if (ind >= 0) {
							this.list.splice(ind, 1);
						}
						this.list.unshift(item);
					}
				});
                this.layoutService.changeChatsSubject.next({ list: this.list });
			}
			if (initialData) {
				initialData = false;
			}
		});


    }

    ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
		this.unsubscribeSnapshot();
    }

}