import { HttpHeaders, HttpClient, HttpResponse, HttpEvent } from '@angular/common/http';
import { Injectable, Optional, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    basePath = environment.apiUrl;

     constructor(
        protected httpClient: HttpClient,
    ) {
    }

    public ssoLogin(token: string, params: any): Observable<any> {
        const headers = new HttpHeaders({ 'authorization': 'Bearer ' + token });
        return this.httpClient.post<any>(`${this.basePath}/protected/sso/login/loginChatWeb`,
            params,
            {
                observe: 'response',
                headers: headers
            }
        );
    }

    public getPublicPolicy(token: string): Observable<any> {
        const headers = new HttpHeaders({ 'authorization': 'Bearer ' + token });
        return this.httpClient.post<any>(`${this.basePath}/protected/sso/system/policy/getPublicPolicy`,
            {},
            {
                observe: 'response',
                headers: headers
            }
        );
    }

    public mobilSignUp(token: string, params: any): Observable<any> {
        const headers = new HttpHeaders({ 'authorization': 'Bearer ' + token });
        return this.httpClient.post<any>(`${this.basePath}/protected/executive_app/mobileSignupApp`,
            params,
            {
                observe: 'response',
                headers: headers
            }
        );
    }

}