import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { NbTokenService } from '@nebular/auth';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({
 providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private nbTokenService: NbTokenService,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.nbTokenService.get().pipe(
            switchMap(data => {
                if (!data.isValid()) {
                    this.router.navigate(['/auth/login']);
                }

                return of(true);
            })
        );
    }
 
}