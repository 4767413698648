import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState, Logout } from '@app/store';
import { NbTokenService } from '@nebular/auth';

@Injectable()
export class UnhautorizedInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
		private store: Store<AppState>,
        private nbTokenService: NbTokenService,
    ) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(err => {
                if ([401].includes(err.status)) {
                    this.nbTokenService.clear();
                    this.store.dispatch(new Logout());
                    this.router.navigate(['/auth/login']);
                }

                return throwError(err);
            })
        );
    }
}