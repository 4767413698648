import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LoginService } from '../modules/auth/login/login.service';
import { EncryptionService } from '@app/shared/services/encryption.service';
import { environment } from '../../environments/environment';
import * as Forge from 'node-forge';

@Injectable()
export class HttpRequestEncryptInterceptor implements HttpInterceptor {

    constructor(
        private loginService: LoginService,
        private encryptionService: EncryptionService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const checkUrl = request.url.indexOf('protected/sso/system/policy/getPublicPolicy');
        if (checkUrl === -1) {
            return this.loginService.getPublicPolicy(environment.tokenUniversal).pipe(
                switchMap((resultado: any) => {
                    const data = resultado.body;
                    const body = request.body;
                    if (!body.file && !body.base64 && !body.b64Content) {
                        const value = JSON.stringify(body);
                        const encryptado = this.encryptionService.encryptWithPublicKeyLong(value, data.mswSANXnWqh);
                        const ob = {
                            "mswSANXnWqh": encryptado,
                            "vRPbyweQpC": data.vRPbyweQpC
                        };

                        const newReq = request.clone({ body: ob });
                        return next.handle(newReq);
                    }

                    return next.handle(request);

                })
            );
        }

        return next.handle(request);
    }

}