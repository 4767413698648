import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '@app/store/app.states';

import { tap, withLatestFrom, mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthEffects {

    constructor(
        private actions: Actions,
        private router: Router,
        private store: Store<AppState>
    ) {
    }

    // login$ = createEffect(() => this.actions.pipe(
    //         ofType<Login>(AuthActionTypes.Login),
    //         tap(action => {
    //             localStorage.setItem('access_token', action.payload.token);
    //             this.store.dispatch(new UserRequested());
    //         }),
    //     ),
    //     { dispatch: false }
    // );

    // loadUser$ = createEffect(() => 
    //     this.actions
    //     .pipe(
    //         ofType<UserRequested>(AuthActionTypes.UserRequested),
    //         withLatestFrom(this.store.pipe(select(isUserLoaded))),
    //         mergeMap(([action, _isUserLoaded]) => this.authService.me()),
    //         tap(response => {
    //             const _user: UserManagement = response.body;
    //             this.store.dispatch(new UserLoaded({ user: _user }));
    //         })
    //     ),
    //     { dispatch: false }
    // );

}
