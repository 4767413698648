import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      <!-- Created by <b><a href="https://ammia.io" target="_blank">Ammia</a></b> 2024 Version 1.4.2 -->
      Created by <b><a href="https://ammia.io" target="_blank">Ammia</a></b> 2024 Version 1.4.2
    </span>
  `,
})
export class FooterComponent {
}
